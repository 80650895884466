#reporting-container {
    flex-grow: 1;
    overflow: auto;

    padding: 40px 56px 0 56px;

    .chart-container {
        height: 450px;

        .chart-canvas {
            max-height: 400px;
        }
    }

    .summary {
        flex-grow: 1;
        margin-right: 35px;

        .summary-unit {

            min-height: 95px;
            min-width: 25%;
            margin-left: 20px;
    
            border-radius: 5px;
        }
    }

}
