#settings-container {
    flex-grow: 1;
    overflow: auto;
    padding: 40px 56px 0 56px;

    .logosettings-container, & .agencysettings-container {
        display: flex;
        flex-direction: column;
        //height: 253px;

        .current-logo, & .current-agency-logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            place-items: center;
            min-width: 441px;
            height: 180px;
            background-color: #F3F3F3;
            margin-right: 32px;
            > img {
                max-width: 441px;
                max-height: 150px;
                object-fit: contain;
            }
        }
    }

    .email-notification {
    }
}
