#account-container {

    padding-left: 55px;
    padding-right: 55px;
    padding-top: 45px;

    flex-grow: 1;
    overflow: auto;

    .account-tab {
        border-right: 1px solid #fff;
    }

    #subscription-container {
        padding: 0;

        .subscription {
            background-color: #fff;

            .title {
                height: 60px;
                color: #4B5A7A;
                background-color: #fff;
    
                padding-top: 15px;
                padding-left: 20px;
            }
        }

        .credit-cards {

            >div {
                display: flex;
                align-items: center;

                overflow: auto;
            }

            .add-creditcard-btn {
                width: 369px;
                height: 214px;
                border: 2px solid #f8f8f8;
                border-radius: 10px;

                display: block;
            }

            .creditcard {
                width: 350px;
                min-width: 350px;
                height: 214px;
                background: linear-gradient(to right, #3b76ef, #0038aa);
                position: relative;

                margin-right: 15px;
                border-radius: 20px;

                .button-container {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;

                    >button {
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    .btn {
                        color:#fff;
                        border-color: #fff;
                    }
                }


            }
        }
    }

    #billinginfo-container {
        height: 625px;
        background-color: #fff;
        padding-top: 70px;

        .billinginfo-content {
            margin: 0 auto;
            min-height: 281px;
            width: 530px;

            text-align: center;
            color: black;

            border: 1px solid rgba(0,0,0,0.16);
            border-radius: 5px;

            .billinginfo-company {
                border-bottom: 1px solid rgba(0,0,0,0.16);
                min-height: 200px;
                padding: 30px 0;

                .company-name {
                    margin: 15px 0;
                }
            }

            .billinginfo-deputy {
                display: flex;
                justify-content: space-between;

                padding: 15px 30px;

                .fullName {
                    text-align: left;    
                }
            }
        }
    }

    // shared styles for account page
    .margin-right {
        margin-right: 10px;
    }
}

.MenuItem {
    color: red;
}

.MenuItem.selected { /* Increase the specificity */
    color: blue;
}

.select {
    
    border-bottom: 1px solid transparent;
}

.select.focused {
    background-color: red;
    border-bottom: 1px solid transparent;
}