// @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

body {
  font-family: 'Nunito' !important;
  color: #4B5A7A !important
};

div {
  box-sizing: border-box;
}

html, body, #root, .App{
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
}

.App {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .systemmsg-container {
    position: fixed;
    z-index: 1000;
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 50px;
    max-width: inherit;
    background-color: black;
    color: red;
    font-weight: 700;
    border-radius: 0;
  }

  .system-msg {
    top: 0;
  }

  .btn-override {
    &:hover {
      background-color: transparent;
    }
  }

  .input-holder {
    width: 250px;
    margin-right: 5px;
  }

  .input-holder.small {
    width: 122px;
  }

  .warning-icon {
    font-size: 3em;
  }

  .content-container {
    height: 100%;
    //margin-top: 10px;
    .App-header {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 3;
      background-color: #FFFFFF;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: calc(10px + 2vmin);
      color: black;
      border-left: 1px solid #F1F1F1;
      border-right: 1px solid #F1F1F1;
      padding: 5px;

      .App-header-group {
        display: flex;
      }

      #menu-list-grow {
        width: 370px;
        color: #3B76EF;

        > li {
          min-height: 55px;
          font-weight: 700;
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
          &:last-child {
            border-bottom: none;
          }
        }

        .user-info {
          padding: 30px;
          min-height: 180px;
          background-color: #F6F6F6;
          color: black;
          .fullName {
            font-weight: 700;
          }

          .orgname {
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }

    }

    .App-content {
      background-color: #F1F1F1;
      padding-top: 55px;
      display: flex;
      min-height: 100%;
      align-items: stretch;
    }
  }

  // classes styled spinner
  .overlay-content {
    position: absolute;
    height: 100vh;
    justify-content: center;
    place-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;

    .overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
      background-color: gray;
      opacity: 0.3;
    }

    .overlay-spinner {
      z-index: 10000;
      color: #0552ed;
    }
  }

  // pages with external access (like submitting feedback or verifying organization)
  .anonymous-page-container {
    background-color: #f1f1f1;
    padding-top: 100px;
    min-height: 100%;

    .anonymous-page {
      margin: 0 auto;
      border-radius: 5px;
      background-color: #FFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 500px;
      //height: 692px;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 19px 25px;
      .anonymous-page-content {
        position: relative;
        .success-message {
          background-color: white;
          padding: 3rem 1rem 1rem;
          margin-top: -2rem;
          border-radius: 0.5rem;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          h4 {
            background-color: #D7EBD9;
            padding: 1rem;
            border-radius: 0.2rem;
            font-weight: bold;
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 525px) {
    .anonymous-page-container {
      padding-top: 20%;

      .anonymous-page {
        width: 100%;
        padding: 5% 5% 0 5%;
      }

      .feedback-title {
        line-height: normal;

        svg {
          min-width: 25px;
        }

        div:first-child{
          height: 163px;
        }
      }
    }
  }
}

.success-title {
  font-size: xx-large;
  font-weight: bold;
  color: #69CC3D;
}

.feedback-title {
  display: flex;
  padding: 0 1rem;
  height: 163px;
}

.circle {
  position: absolute;
  background-color: #fff;
  top: -30px;
  left: 43%;
  width: 60px;
  height: 60px;
  border-radius: 50px;

  .feedback-icon {
      margin-top: 5px;
  }
}

.feedback-submitted {
  padding: 10px;
  background-color: #E1FFEC;
  border-radius: 5px;
}

.submit-feedback-popup {
  &.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 435px;
  }
}

.table-header {
  background-color: #b9b9b9;
  text-align: center;
  font-weight: 700;
  border-right: #F1F1F1;
}

.tbl {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;

  tr {
    height: 50px;
    // max-height: 50px;

    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th, td {
    border-right: 1px solid rgba(224, 224, 224, 1);
    max-width: 350px;

    &:last-child {
      border-right: 0;
    }
  }

  th {
    font-weight: 700;
  }
}

.content-block {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  &.table {
    padding: 0;

    .title {
      padding: 0 15px;
      height: 63px;
      display: flex;
      align-items: center;
    }
  }
}

.setup-content-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;

  .setup-content-block-left {
    width: 100%;
    //min-width: 732px;
    flex-direction: column;
  }

  .setup-content-block-right {
    background-color: #FFFFFF;
  }
}

.custom-radiobtn {
  cursor: pointer;

  & div, img {
    cursor: pointer;
  }

  &.active {
    border: 1px solid #3B76EF;
    //border-radius: 5px;
  }
}

.select-holder {
  border-radius: 30px;

  &:focus {
    border-radius: 30px;
  }
}

.input-field-select {
  border-radius: 20px !important;
}

.margin-right {
  margin-right: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.textColor-base {
  color: black;
}

.dialog-content {
  > div {
    margin-bottom: 10px;
  }

  text-align: center;
}

.filter-container {
  display: flex;
  align-items: center;

  > p {
    margin-right: 15px;
  }

  .filter-list {
    width: 150px;
  }
}

.dnd-container {
  flex-grow: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 2px solid #cacaca;
  padding: 7px;
  width: 100%;
  height: 100%;

  &>div {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &>p {
    align-self: flex-end;
  }

  .dnd {
      background-color: rgba(248, 248, 248, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
  }
}

.otp-inputElement {
  border-color: #E5E5E5;
  border-style: solid;
  border-radius: 5px;
  font-size: 36pt;
  margin-right: 5px;
  margin-left: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.review-rating {
  background-image: url("../src/png/star-yellow.png");
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1rem;
  font-weight: bold;
}
