#setup-container {
    flex-grow: 1;
    padding: 40px 56px 0 56px;

    #importfrom-container {
        background-color: #fff;
        min-height: 255px;

        display: flex;
        flex-direction: column;

        .emailclient-container {
            padding: 20px 30px 0 30px;

            &>div {
                margin-right: 100px;
            }

            .emailclient {
                border: 1px solid transparent;
                border-radius: 5px;
                padding: 10px;

                &.active {
                    border-color: #3B76EF;
                }
            }

            .connect-btn {
                align-self: center;
                flex-grow: 1;
                margin-right: 100px;
            }

        }

        .note {
            font-weight: 700;
            color: red;
            margin: 0 auto 10px auto;
        }

        .csv-container {
            flex: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    #template-container {

        .customize {
            display: flex;
            justify-content: space-between;

            .colorPalette-container {

                .colorPalette {
                    display: flex;
                    grid-template-columns: repeat(8, 1fr);
                    margin: 12px 0;
                    flex-wrap: wrap;

                    .color-btn {
                        box-sizing: border-box;
                        width: 30px;
                        height: 30px;
                        align-items: center;
                        text-align: center;

                        &.active {
                            border-color: transparent;
                        }
                    }
                }

            }
        }

        .icon-template {


            .iconSet-container {
                cursor: pointer;
                background-color: #F8F9FB;

                flex-grow: 1;

                min-width: 530px;
                min-height: 150px;
                padding: 10px;

                &.active {
                    background-color: #fff;
                    border-color: transparent;
                }

                .iconSets-detail {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;

                    align-items: center;

                    .type {
                        align-self: flex-start;
                    }
                }

                .iconSets {
                    display: flex;
                    justify-content: space-around;
                }
            }

        }
    }

    #signature-container {
        max-height: 650px;
        overflow-x: auto;

        .signature {
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
            padding: 15px 20px 15px 15px;
            &:last-child {
                border-bottom-color: transparent;
            }

            .agent-container {
                border: 1px solid rgba(224, 224, 224, 1);
                border-radius: 5px;
                overflow: hidden;
                align-items: normal;

                min-height: 100px;

                &>div {
                    display: flex;
                    align-items: center;
                }
            }

            .snippet {
                text-align: center;
                //pointer-events: none;
            }

            .btn-container > button {
                margin-top: 5px;
            }
        }
    }

    .preview {
        min-height: 160px;

        .icons {
            display: flex;
            justify-content: center;
            padding: 0 1.5rem;
            max-width: 1200px;
            margin: 0 auto;

            &img {
                padding: 0 1.5rem;
            }
        }

    }

    .step {
        &.active {
            background-color: #3B76EF;
        }

        .step-label {
            text-align: center;
            width: 100%;
        }
    }

}

input[type="file"] {
    display: none;
}

.custom-file-upload {

    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.listItem:hover {
    font-weight: bold;
}

.agentSnippet .MuiButton-label {
    padding: 0.2rem;
}
