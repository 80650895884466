.entry-container-wrapper {
    min-height: 100%;
    width: 100%;
    display: inline-flex;
    
    .entry-container {
        margin: 0 auto;
        padding: 10vh 1rem 1rem 1rem;
        width: 100%;
        height: 100%;
        text-align: center;
        .entry-box-title {
            display: flex;
        }

        .entry-option {
            background-color: rgba(249, 249, 249, 1);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            margin-bottom: 10px;
            .entry-option-title {
                padding: 10px 0;
            }
        }

        .entry-box {    
            display: flex;
            align-items: initial;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 25px 65px rgba(0,0,0,0.05);;
            .entry-box-item {
                width: 100%;
                &:first-child {
                    margin-right: 10px;
                }
                .title {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    min-height: 65px;
                }
                .isTermsApproved2 {
                    visibility: hidden;
                }
            }
        }
    }

    .banner-section {
        background-color: #3B76EF;
        position: relative;
        height: 100%;
        color: #fff;
        .ellipses {
            position: absolute;
            top: -300px;
            left: -350px;
            // background-blend-mode: soft-light;
        }

        .info-container {
            height: 100%;
            padding: 50px 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .title-info {
                border-left: 1px solid #fff;
                padding-left: 20px;
                margin-top: 50px;
                max-width: 560px;
                z-index: 1;
            }

            .trial-info {   
                text-align: center;
                z-index: 1;
                >p:last-child {
                    margin-top: 10px;
                }
            }
        }
    }

    .icons {
        position: absolute;
        top: 200px;
        right: -65px;
        z-index: 1000;

        >div {
            margin-bottom: 10px;
        }
    }
}
.trial-info-footer {
    background-color: #3B76EF;
    color: white;
    text-align: center;
    padding: 1rem 1.5rem;
}

.login-container-wrapper {
    min-height: 100%;
    background-image: url(../img/entrypage-background.png), url(../img/image-background.png);
    background-size: cover;
    background-position-x: 95%;

    .login-container {
        text-align: center;

        .login-box {
            padding: 1rem;
            .login-box-item {
                width: 100%;
                height: auto;

                margin: 0 auto;
                padding-top: 15vh;
              
            }
        }
    }
}

.sign-box {

    padding: 30px;
    // min-height: 520px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #fff;
    // color: #fff;
    border-radius: 10px;

    .title {
        font-weight: bold;
        margin-bottom: 30px;
    }

    .sign-box-item {
        margin-bottom: 20px;
    }

    .sign-box-btn {
        height: 50px;
        border-radius: 0;

        width: 100%;
        margin-bottom: 10px;

        &.colored {
            background-color: rgba(0, 0, 0, 0.1);
        };

        &.fb {

            background-color: #4c69ba;
            color:rgb(225, 225, 255)
        }
    }
}

.logo-container {
    margin-bottom: 30px;
}

#home-container {

    display: flex;

    height: 100px;
    border: 1px solid #3B76EF;

    >div {
        min-width: 150px;
        border-right: 1px solid #3B76EF;

        text-align: center;
        line-height: 100px;

        &.empty {
            flex-grow: 1;
        }
    }
}

.terms-approved-checkbox {
    //visibility: hidden;
    opacity: 0;
    
}

/* Large desktops and laptops */
@media (min-width: 1200px) {

}
/* Landscape tablets and medium desktops */
@media (min-width: 768px) {
    .login-container-wrapper {
        .login-box {
            .login-box-item {
                max-width: 500px;
            }
        }
    }
    .entry-container-wrapper {

        .entry-container {
            width: 600px;
            padding: 10vh 50px 0 50px;
        }
    }
}

/* Landscape tablets and medium desktops */
@media (max-width: 1199px) {
    .entry-container-wrapper {
        background-color: #F1F1F1;

        .entry-container {
            border: none;
        }
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 992px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .entry-container-wrapper {
        .entry-container {
            .entry-box {
                flex-wrap: wrap-reverse;

                .entry-box-item {

                    .sign-box {
                        min-height: auto;
                        border-color: transparent;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}